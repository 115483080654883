import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import Div from './../components/Div'
import OutlineButton from '../components/OutlinedButton'
import DisasterRecoverySvg from '../images/disaster-recovery/disaster-recovery.svg'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledDisasterRecovery,
  header,
} from '../components/styles/DisasterRecovery.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledDisasterRecovery}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span>DISASTER RECOVERY</span><br />
              Garantindo que seus dados estejam seguros.
            </H4>
            <Paragraph>Dispomos de serviços completos de Disaster Recovery, agregando infraestrutura computacional, conectividade, soluções de replicação e gerenciamento para sanar e prevenir problemas graves no sistema, além de preservar os dados e a disponibilidade como se nada tivesse acontecido.</Paragraph>
            <Paragraph>Os planos de recuperação de dados da HostDime são totalmente personalizáveis. Entre em contato agora para começar a proteger seus dados!</Paragraph>
            <Div
              style={{
                marginTop: 16,
                marginBottom: 32
              }}
            >
              <img
                src={DisasterRecoverySvg} 
                style={{
                  margin: '0 auto',
                  width: 200
                }}
              />
            </Div>
            <Paragraph>Você precisa que seus dados estejam em um Data Center seguro para o caso de um desastre natural ou causado pelo homem. Ou, talvez, precise que backups sejam feitos todas as noites. Para isso, a HostDime tem a solução perfeita de Disaster Recovery para o seu negócio. Tudo isso para que a sua empresa esteja preparada e disponível diante de qualquer situação adversa.</Paragraph>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <OutlineButton
                type='btnOutlinedMedium_'
                Link='/contatos'
              >
                ENTRE EM CONTATO
              </OutlineButton>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Disaster Recovery | Proteja seus dados' 
        description='O Disaster Recovery garante infraestrutura e soluções de replicação e gerenciamento para preservar seus dados e manter sua disponibilidade.' 
      />
      <PageTemplate
        title='Disaster Recovery'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "disaster-recovery/garantindo-seus-dados.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default StaticPage
